import React from 'react';
import { IonButton,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonCard, IonCardContent, IonImg,IonIcon, IonRow, IonCol } from '@ionic/react';
import { useState,useEffect } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import { navigate,mailOutline,call } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import { Link } from 'react-router-dom';



const Home: React.FC = (prop:any) => {
  const { height, width } = useWindowDimensions();
  const platform = isPlatform("ios");
  console.log("Platform ",platform)
  console.log(prop);
  
  function clicked(){
    console.log("Clicked");
  }
  function waCS(){
    window.location.href = "whatsapp://send?&phone=628121800530&text=Halo%20Zicoster%20Towing"
  }
  function mail(){
    window.location.href = "mailto:zicostertowing@gmail.com"
  }
  function phoneCall(){
    window.location.href = "tel:+628121800530";
  }

  return (
    <IonPage>
      <IonContent fullscreen class="ion-padding">
      <IonImg class="typelogo" src="/assets/images/Zicoster-item.png"/>


      <IonRow color="primary" class="ion-padding">
        <IonCol>
          <IonButton expand='block' color={platform ? 'light':'dark'} onClick={()=>{mail()}}>
            <IonIcon icon={mailOutline} />
          </IonButton>
        </IonCol>
        <IonCol>
          <IonButton expand='block' color={platform ? 'light':'dark'} onClick={()=>{phoneCall()}}>
            <IonIcon icon={call} />
          </IonButton>
        </IonCol>
      </IonRow>


      <IonRow class="ion-padding rowcard" onClick={()=>{waCS()}}>
        <IonCol class="wabackground">
          <IonImg src="/assets/images/wa.png" class="walogo"/>
        </IonCol>
        <IonCol class="walabel">
          <h4 color={platform ? 'light':'dark'}>Whatsapp</h4>
        </IonCol>
        <IonCol></IonCol>
        <IonCol></IonCol>
      </IonRow>


      <Link to="/tracking">
        <IonCard color="light" onClick={()=>{clicked()}}>
          <IonImg src="/assets/images/towing-silvia.jpg" alt="" />
          <IonRow  color={platform ? 'light':'dark'} class="trackingbutton">
            <IonIcon icon={navigate} class="trackingicon"></IonIcon>
            TRACKING
          </IonRow>
        </IonCard>
      </Link>
      </IonContent>
    </IonPage>
  );
};

export default Home;























function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}