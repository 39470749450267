import React from 'react';
import { useIonToast,IonButton,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonCard, IonCardContent, IonImg,IonIcon, IonRow, IonCol, IonInput, IonGrid } from '@ionic/react';
import { useState,useEffect,useCallback } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import './Tracking.css';
import { navigate,mailOutline,call, arrowBack, chevronBack } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import {db} from '../firebase';
import { collection, getDocs,query, where} from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import moment from 'moment';

const Tracking = (prop,match) => {

    const { height, width } = useWindowDimensions();
    const [trackingCode,setTrackingCode] = useState(null);
    const platform = isPlatform("ios");
    const [present] = useIonToast();

    var nav = prop;
    // console.log("Nav:",nav);
    var splitted = nav.location.pathname.split("/");
    var id = splitted[2];
    
    useEffect(()=>{
        if(typeof id != "undefined" && id != null && id.length > 0){
            console.log("Got tracking id",id.toUpperCase())
            setTrackingCode(id.toUpperCase())
            setTimeout(()=>{
                console.log("Searching tracking code:",trackingCode.toUpperCase(),id.toUpperCase());
                // console.log("DB:",db)
                if(trackingCode != null){
                    search();
                }
            },1000);
        }
    },[trackingCode])
    

    console.log(height,width);
  
    function clicked(){
        console.log("Clicked");
        nav.history.goBack()
    }
    function inputChanged(e:any){
        var code = e.target.value;

        setTrackingCode(code.toUpperCase());
    }

    async function search(){
        console.log("Finding tracking "+trackingCode);
        const presentToast = (position: 'top' | 'middle' | 'bottom') => {
            present({
              message: 'Finding Active Tracking Code',
              duration: 1500,
              position: position,
              color:platform ? 'light':'dark'

            });
        };
        presentToast("bottom")
        setTimeout(async ()=>{
            const driverRef =  collection(db,"driver");
           
            const q = query(driverRef,where("trackingCode","==",trackingCode));
            const querySnapshot = await getDocs(q);
            if(querySnapshot.empty){
                console.log(querySnapshot);
                const failedToast = (position: 'top' | 'middle' | 'bottom') => {
                    present({
                      message: 'Tracking Code Not Found',
                      duration: 1500,
                      position: position,
                      color:platform ? 'light':'dark'
                    });
                };
                return failedToast("bottom")
            }
            querySnapshot.forEach(async(res) => {
                console.log(res.id, " => ", res.data());
                var data = res.data();
                console.log("Start :",moment(data.trackingDuration.from).fromNow());
                console.log("To :",   moment(data.trackingDuration.to).fromNow());
                const docRef = doc(db, "fleet", data.vehicle);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    var fleet = docSnap.data();
                    nav.history.push("/track",{fleet:fleet,driver:data});
                    
                  } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                  }
               
            });

        },2000);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonGrid fixed={true}>
                    <IonRow>
                        <IonCol sizeMd='1'>
                            <IonButton fill="clear" class="backbutton" color={platform ? 'light':'dark'} onClick={()=>{clicked()}}>
                                <IonIcon icon={chevronBack}/>    
                            </IonButton>    
                        </IonCol>    
                        <IonCol sizeMd='1'>
                            <IonImg class="headerlogo" src="/assets/images/Zicoster-item.png"/>
                        </IonCol>    
                        <IonCol sizeMd='1'></IonCol>    
                    </IonRow>   
                </IonGrid>
            </IonHeader>
            
            <IonContent fullscreen class="ion-padding">
                <IonCard>
                    <IonRow class="ion-padding">
                        <IonIcon icon={navigate} class="trackingicon"></IonIcon>
                        <IonText>Tracking Code</IonText>
                    </IonRow>
                    <IonCardContent class="card-dark ion-padding">
                      
                        <IonInput color={platform ? 'dark':'light'} value={trackingCode} onIonInput={(e:any)=>{e.preventDefault();inputChanged(e)}} placeholder='_ _ _ _ _' class="input"></IonInput>
                    </IonCardContent>
                
                </IonCard>
                <IonRow>
                    <IonCol></IonCol>
                    <IonCol>
                        <IonButton color="danger" class="searchbutton" onClick={()=>{search()}}>
                            SEARCH
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default Tracking;























function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}