import axios from 'axios';

// var cartrack_url = "https://fleetapi-id.cartrack.com/rest/"
// var cartrack_url = "/rest/"
var cartrack_url = "https://us-central1-zicoster-towing.cloudfunctions.net/rest/rest/";
// var env = "development";
var env = "production";
var google_maps_api_key = "AIzaSyChGOqaztNOqBJYgxpLXSUWjCex8nnMtZQ";
var geocode_url = "https://maps.googleapis.com/maps/api/geocode/json?key=";
var searchURL = "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=";
var cartrack_username = "HARN00001";
var cartrack_password = "d2840985b5fd623e37cd302e7227022e61b6b4f3f7a15ea426cb179b38213b37";
var base64 = require('base-64');
var $  = require("jquery")



const api = {
  environment : env,
  google_maps_api_key : google_maps_api_key,
  geocode_url : geocode_url + google_maps_api_key,
  searchurl : searchURL + google_maps_api_key,





  geocode : function(lat,lng,callback){

    fetch(this.geocode_url+"&latlng="+lat+","+lng,{method:"GET","Accept":"application/json","Content-Type":"application/json"}).then((response)=>response.json()).then((json)=>{
      return callback(true,json);
    }).catch((er)=>{
      return callback(null,er)
    })
  },
  mapsuggestion:function(search,callback){
    fetch(this.searchurl + "&output=json&input="+search,{method:"GET","Accept":"application/json","Content-Type":"application/json"})
    .then((response)=>response.json()).then((json)=>{
      return callback(true,json)
    }).catch((er)=>{
      return callback(false,er);
    })
  },
  reversegeocode : function(placeid,callback){
    console.log(this.geocode_url +"&placeID="+placeid)
    fetch(this.geocode_url +"&place_id="+placeid,{method:"GET","Accept":"application/json","Content-Type":"application/json"})
    .then((response)=>response.json()).then((json)=>{
      return callback(true,json)
    }).catch((er)=>{
      return callback(false,er)
    })
  },
  cartrack_getdata :function(callback){
    console.log(btoa(cartrack_username+":"+cartrack_password))
    fetch(cartrack_url + "get_vehicle_list_all_details",
      {
        method:"GET",
        "Accept":"application/json",
        "Content-Type":"application/json",
        headers:{
          "Authorization" : "Basic "+base64.encode(cartrack_username+":"+cartrack_password)
          }
      }).then((response)=>response.json()).then((json)=>{
      return callback(true,json)
    }).catch((er)=>{
      return callback(false,er);
    })
  },
  cartrack_getdatabyregistration: async function(licenseplate,callback){
    $.getJSON({
      url:cartrack_url+"get_vehicle_state?reg="+licenseplate,
      method:"GET",
      crossDomain:true,
      headers:{
        "Authorization":"Basic "+base64.encode(cartrack_username+":"+cartrack_password),
        "Content-Type":"application/json",
        "Accept":"application/json",
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Credentials': 'true'
      }
    },(success)=>{
      // console.log("Success",success);
      return callback(true,success)
    },(err)=>{
      // console.log("HTTP error",err)
      return callback(false,err)
    })

    // axios({
    //     url:cartrack_url+"get_vehicle_state?reg="+licenseplate,
    //     method:'GET',
    //     body:"",
    //     preflightContinue: true,
    //     "headers":{
    //         "Authorization":"Basic "+base64.encode(cartrack_username+":"+cartrack_password),
    //         "Content-Type":"application/json",
    //         "Accept":"application/json",
    //         "Access-Control-Allow-Origin":"*",
    //         "Access-Control-Allow-Headers": "*",
    //         'Access-Control-Allow-Credentials': 'true'
    //     },
    // }).then((response)=>{
    //     console.log("Response:",response);
    //     return callback(true,response.data)
    // }).catch((er)=>{
    //   console.log("ERROR GET:",er)
    //   return callback(false,er)
    // })
    // console.log(cartrack_url+"get_vehicle_state?reg="+licenseplate,btoa(cartrack_username+":"+cartrack_password));
    // var headers = new Headers();
    // headers.append('Authorization', 'Basic ' + btoa(cartrack_username + ":" + cartrack_password));
    // headers.append("Content-Type" , "application/json");
    // headers.append("Referrer-Policy","no-referrer-when-downgrade");
    // fetch(cartrack_url+"get_vehicle_state?reg="+licenseplate,
    //   {
    //     "method":"GET",
    //     "Accept":"application/json",
    //     "mode":"no-cors",
    //     "cache": "no-cache",
    //     "username":cartrack_username,
    //     "password":cartrack_password,
    //     "headers":headers
    //   }).then( response =>response.json()).then((json)=>{
    //   return callback(true,json)
    // }).catch((er)=>{
    //   return callback(false,er);
    // })
  }
}


export default api;
