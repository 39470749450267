import { Redirect, Route, RouteComponentProps,Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Tracking from './pages/Tracking';
import Track from './pages/Track';
import './App.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC  = (match:any) => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Switch>

        <Route exact path="/home" component={Home}/>
        <Route path="/tracking"  component={Tracking}/>
        <Route exact path="/track" component={Track}/>
        <Route exact path="/.well-known/apple-app-site-association">
          <Redirect to="/.well-known/apple-app-site-association" />
        </Route>
        <Route exact path="/.well-known/assetlinks.json">
          <Redirect to="/.well-known/assetlinks.json" />
        </Route>
        <Route exact path="*">
          <Redirect to="/home" />
        </Route>
        </Switch>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
