import React from 'react';

import { useIonToast,IonButton,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonCard, IonCardContent, IonImg,IonIcon, IonRow, IonCol, IonInput, IonGrid, IonAvatar, IonSegment, IonSegmentButton ,IonLabel, IonItem} from '@ionic/react';
import { useState,useEffect,useCallback } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import './Track.css';
import { navigate,mailOutline,call, arrowBack, chevronBack, refresh, refreshOutline, logoWhatsapp } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import {db} from '../firebase';
import { collection, getDocs,query, where } from "firebase/firestore";
import moment from 'moment';
import GoogleMaps from './Map';
import api from './Api';  



const Track: React.FC = (prop,match) => {

    const { height, width } = useWindowDimensions();
    const [trackingCode,setTrackingCode] = useState(null);
    const [marker,setMarker] = useState({lat: -6.187119,lng:106.8205002});
    const [locationChanged,setLocationChanged] = useState(true);
    const [cartrack,setCartrack] = useState(null);
    const platform = isPlatform("ios");
    const nav:any = prop;
    var timer = null;
    console.log(height,width)
    useEffect(()=>{
        clearInterval(timer);
        timer = setInterval(()=>{
            getData()
        },10000)

        console.log("Clearing")
    },[timer])
    
   
   
    function clicked(){
        nav.history.goBack();
    }
    function refresh(){
        // window.location.reload();
        getData();
    }
    function waDriver(){
        var phoneNumber = nav.location.state.driver.phoneNumber;
        var replacer = "62";
        window.location.href = "whatsapp://send?&phone="+replacer.concat(phoneNumber.slice(1))+"&text=Halo%20"+nav.location.state.driver.displayName;
        console.log("whatsapp://send?&phone="+replacer.concat(phoneNumber.slice(1))+"&text=Halo%20"+nav.location.state.driver.displayName)
    }
    function callDriver(){
        var phoneNumber = nav.location.state.driver.phoneNumber;
        var replacer = "62";
        window.location.href = "tel:+"+replacer.concat(phoneNumber.slice(1));
    }
    
   function getData(){
        console.log("Getting data");
        api.cartrack_getdatabyregistration(nav.location.state.fleet.vehicleLicensePlate,(success:any,result:any)=>{
            if(success){
                result.map((r)=>{
                    setCartrack(r);
                    console.log(r)
                    // console.log(marker,{lat:parseFloat(r.latitude),lng:parseFloat(r.longitude)});
                    if(marker.lat != parseFloat(r.latitude) && marker.lng != parseFloat(r.longitude)){
                        if(parseFloat(r.latitude) != NaN && parseFloat(r.longitude) != NaN){
                            setMarker({
                                lat:parseFloat(r.latitude),
                                lng:parseFloat(r.longitude),
                            });
                            console.log("Location changed")
                            setLocationChanged(true);
                        }
                    }
                    
                    else{
                        console.log("Location same")

                        setLocationChanged(false);
                    }

                })
            }
            else{
                console.log(success,result)
            }
        })
   } 
    return (
        <IonPage>
            
            <IonHeader class="floatingheader">
                <IonGrid fixed={true}>
                    <IonRow>
                        <IonCol sizeMd='1'>
                            <IonButton fill="clear" class="backbutton" color={platform ? "dark":'light'} onClick={()=>{clicked()}}>
                                <IonIcon icon={chevronBack}/>    
                            </IonButton>    
                        </IonCol>    
                        <IonCol sizeMd='1'>
                            <IonImg class="headerlogotracking" src="/assets/images/Zicoster-item.png"/>
                        </IonCol>    
                        <IonCol sizeMd='1'>
                            <IonButton fill="clear" class="refreshbutton" color={platform ? "dark":'light'} onClick={()=>{refresh()}}>
                                <IonIcon icon={refreshOutline}/>    
                            </IonButton>  
                        </IonCol>    
                    </IonRow>   
                </IonGrid>
            </IonHeader>
            {nav.location.state?.driver ? (
                <IonContent fullscreen>
                <GoogleMaps width={width} height={height} marker={marker}/>
                <IonCard class="bottomsheet">
                    <IonItem class="rowitemcard" color={"#ffffff"} lines='full'>
                        <IonCol sizeMd='1' class="avatar">
                            <IonAvatar>
                                <img src={nav.location.state.driver.photoURL}/>
                            </IonAvatar>
                        </IonCol>
                        <IonCol sizeMd='1' class="driver">
                            <IonRow color="#ffffff">
                                <IonLabel color={platform ? 'light':'dark'}>
                                    Driver
                                </IonLabel>
                            </IonRow>
                            <IonRow >
                                <IonText>
                                {nav.location.state.driver.displayName}
                                </IonText>
                                </IonRow>
                        </IonCol>
                        <IonCol sizeMd='1' class="buttongroup">
                                <IonCol size='sm' color={platform?'light':'dark'} class="buttonleft" onClick={()=>{waDriver()}}>
                                    <IonIcon icon={logoWhatsapp} />
                                </IonCol>
                                <IonCol size='sm' color={platform?'light':'dark'} class="buttonright" onClick={()=>{callDriver()}}>
                                    <IonIcon icon={call} />
                                </IonCol>
                        </IonCol>
                    </IonItem>
                    <IonRow class="ion-padding fleetinfo" color={platform ? 'dark':'light'}>
                        {cartrack != null ? (
                            <>
                            <IonLabel>{moment(cartrack.event_ts).fromNow()}</IonLabel>
                            <IonLabel>{nav.location.state.fleet.vehicleLicensePlate}</IonLabel>
                            <IonLabel>{cartrack.pd}</IonLabel>
                            </>

                        ):(<IonLabel>Waiting fleet data...</IonLabel>)}
                    </IonRow>
                </IonCard>
            </IonContent>
            ):(<></>)}
            


        </IonPage>
    );
};



export default Track;
























function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}