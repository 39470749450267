import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import mapstyle from './Mapstyle';
const containerStyle = {
  width: '400px',
  height: '400px'
};



function MyComponent(prop) {
    // console.log("Map prop:",prop.marker,mapstyle)

    const [center,setCenter] = useState({
            lat: -6.187119,
            lng:106.8205002
    })
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyChGOqaztNOqBJYgxpLXSUWjCex8nnMtZQ"
    })
    const [map, setMap] = React.useState(null)

    useEffect(()=>{
        var m = map;
        if(m != null){
            m.panTo(prop.marker)
        }
    },[prop.marker]);


    const centerChanged = React.useCallback(function callback(m) {
    //    console.log("Center:",map,m)
    }, [])

    const onLoad = React.useCallback(function callback(m) {
        // console.log("Onload",m)
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(prop.marker);
        if(m != null){
            m.panTo(prop.marker);
            if(prop.marker.lat ==  -6.187119 && prop.marker.lng == 106.8205002 ){

            }
            else{
                m.setZoom(15);
            }
            setMap(m)
        }
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{
                width:prop.width,
                height:prop.height * 0.685
            }}
            defaultOptions={{ styles: mapstyle,disableDefaultUI:true }}
            styles={mapstyle}
            center={center}
            zoom={prop.marker.lat ==  -6.187119 && prop.marker.lng == 106.8205002 ? 10:14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onCenterChanged={centerChanged}
        >
            { prop.marker.lat ==  -6.187119 && prop.marker.lng == 106.8205002 ? <p></p>: <Marker position={prop.marker} 
            icon={{
                url:"/assets/images/Loc.png",
                scaledSize: {width:50,height:50}
                }}/> }
           
            <></>
        </GoogleMap>
    ) : <></>
}

export default React.memo(MyComponent)