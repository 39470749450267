 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getFirestore } from "firebase/firestore";
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries
 // Your web app's Firebase configuration
 const firebaseConfig = {
    apiKey: "AIzaSyD53fDIlfDBJavaF-k1324fI7pGjtJVUZo",
    authDomain: "zicoster-towing.firebaseapp.com",
    databaseURL: "https://zicoster-towing.firebaseio.com",
    projectId: "zicoster-towing",
    storageBucket: "zicoster-towing.appspot.com",
    messagingSenderId: "268175653029",
    appId: "1:268175653029:web:836fc6cad3f9ee46c7754c",
    measurementId: "G-Y4GDKFVF70"
  };
 
 const app = initializeApp(firebaseConfig);
 // Export firestore database
 // It will be imported into your react app whenever it is needed
 export const db = getFirestore(app);